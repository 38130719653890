/*------------------------------------*\
    #COMPONENTS - CUSTOM
\*------------------------------------*/

.menu-link {
	width: 130px;
	height: 130px;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	font-size: 13px;
	color: #ffffff;
	font-weight: 400;
	padding: 10px 0 20px;

	&--green {
		background-color: #2ab3b1;
		box-shadow: 5px 5px 0px 0px #444444;
	}

	&--dark-grey {
		background-color: #444444;
		box-shadow: 5px 5px 0px 0px #2ab3b1;
	}

	img {
		width: 70px;
	}
}

.cart {
	background-color: #fff;
	padding: 10px;
	border-radius: 15px;
	filter: drop-shadow(-1.302px 3.782px 2px rgba(216,216,217,0.87));

	&.cart--full-height {
		height: 100%;
	}

	&.cart--green {
		background-color: #2ab3b1;
	}
}

.cart--accordion {

	.cart__header {
		&.is-active {
			img {
				transform:  rotate(180deg);
			}
		}
	}
	.cart__body {
		border-top: 1px solid #444444;
		margin-top: 10px;
		padding-top: 10px;
	}
}

.cart__header--grid {
	gap: 5px;
}

.cart__footer--grid {
	gap: 10px;
}

.cart-main-menu {
	height: calc(100vh - 66px);
	background-image: url("../images/KlanjePozadina.png");
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background-size: cover;

	ul {
		margin-bottom: 10px;
		gap: 10px;
	}
}

.grid-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	gap: 10px;
	width: 100%;

	&.grid-wrapper-four-row {
		grid-template-rows: auto auto auto 1fr; 

	}

	&.grid-wrapper-five-row {
		grid-template-rows: auto auto auto auto 1fr; 

	}
	&.grid-wrapper-six-row {
		grid-template-rows: auto auto auto auto auto 1fr; 

	}

	&.grid-wrapper-two-columns {
		grid-template-columns: auto 1fr;
	}

	&.grid-wrapper-single-row {
		grid-template-rows: 1fr;
	}

	&.grid-wrapper-three-row {
		grid-template-rows: auto auto 1fr;
	}
}

.aside-menu {
	width: 60px;
	display: flex;
	flex-direction: columns;
	justify-content: center;
	min-height: calc(100vh - 66px);
}

.tree-menu {
	width: 350px;
	flex: 0 0 350px;
	border: 1px solid #069b9d;
	min-height: calc(100vh - 66px);

	.cart__header {

		a {
			border:1px solid #808080;
			background-color: #fff;
			width: 44px;
			height: 44px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		h2 {
			position: relative;

			&:before {
				position: absolute;
				content:"";
				background-color: #808080;
				height: 1px;
				width: 44px;
				right: 100%;
				top: 50%;
				margin-right: 10px;

			}

			&:after {
				position: absolute;
				content:"";
				background-color: #808080;
				height: 1px;
				width: 44px;
				left: 100%;
				top: 50%;
				margin-left: 10px;

			}
		}
	}
}

.aside-menu__list {
	a {
		width: 44px;
		height: 44px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover,
		&.is-active {
			background-color: #069c9e;
		}
	}

	li {
		margin-bottom: 10px;
	}
}

.number-wrapper {
	height: 44px;
	border-radius: 22px;
	color: #444444;
	background-color: #d8d8d9;
	display: flex;
	align-items: center;
	padding: 0 15px;
	font-size: 14px;
	white-space: nowrap;
}

.tab-list {
	border-bottom: 1px solid #808080;
	width: 100%;

	li {
		margin-right: 20px;
	}

	a {
		font-size: 21px;
		color: #050000;
		padding-bottom: 10px;
		display: block;
		position: relative;

		&.is-active {
			font-weight: 700;

			&:before {
				visibility: visible;
				opacity: 1;
			}
		}

		&:before {
			position: absolute;
			left: 0;
			top: 100%;
			content:"";
			width: 80%;
			height: 6px;
			background-color: #069c9e;
			transform: translate(0,-50%);
			border-radius: 3px;
			visibility: hidden;
			opacity: 0;
			transition: all .3s ease;
		}
	}
}

.rotated-text {
	color: #fff;
	font-weight: 700;
  	writing-mode: vertical-lr;
    transform: rotate(-180deg);
  	white-space: nowrap;
  	display: flex;	
  	align-items: center;	
  	justify-content: center;	
  	width: 100%;
}

.calendar-bgr {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-image: url("../images/PozadinaPola.png");
	flex: 0 0 672px;
	padding-top: 130px;
	padding-bottom: 20px;
	height: calc(100vh - 66px); 

	.logo {
		max-width: 455px;
	}

	img:not(.logo) {
		max-width: 618px;
	}

}


.calendar-main {
	height: calc(100vh - 66px); 
	// overflow: auto;
}

.calendar-grid {
	display: grid;
	height: 100%;
	grid-template-columns: 290px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 50px 1fr 1fr 1fr 1fr 1fr;
	grid-template-areas: 
    "nav day1 day2 day3 day4 day5 day6 day7"
    "aside day day day day day day day"
    "aside day day day day day day day"
    "aside day day day day day day day"
    "aside day day day day day day day"
    "aside day day day day day day day";

}

.calendar-nav {
	grid-area: nav;
	border-bottom: 1px solid #444444;
	padding-right: 20px;

	.number-wrapper {
		height: 40px;
	}
}
.calendar-aside {
	grid-area: aside;
	border-right: 1px solid #e5e7e9;
}

.calendar-day-name {
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #444444;

}


.calendar-day {
	border-right: 1px solid #e5e7e9;
	border-bottom: 1px solid #e5e7e9;
	text-align: center;
	font-size: 12px;

	span {
		min-width: 22px;
		height: 22px;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 3px;
	}

	&.calendar-day-current {
		span {
			background-color: #d4f0ef;
		}
	}
}

.calendar-day-box {

}

.row-box {
	color: #fff;
	font-size: 11px;
	display: flex;
	align-items: center;
	padding: 0 2px;
	text-align: left;
	height: 16px;
	margin-bottom: 2px;
	margin-left: 3px;
	border-radius: 0 2px 2px 0;
	position: relative;

	&:before {
		width: 2px;
		height: 100%;
		content:"";
		border-radius: 2px 0 0 2px;
		background-color: #0096e5;
		position: absolute;
		right:100%;
		margin-right: 1px;
		top: 0;
	}

	&--blue {
		background-color: #039be5;
	}

	&--red {
		background-color: #d50000;
	}

	&--blue-purple {
		background-color: #3e50b4;
	}

	&--purple {
		background-color: #8e24aa;
	}

	&--big {
		height: 30px;
		font-size: 14px;
		margin: -15px -7px -15px 0;
		border-radius: 0 4px 4px 0;

		&:before {
			width: 8px;
			border-radius: 4px 0 0 4px;
		}
	}
}

.small-calendar__body {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 30px 30px 30px 30px 30px 30px 30px;

	.cell {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;

		span {
			min-width: 30px;
			height: 30px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		&--current {
			span {
				background-color: #d4f0ef;
			}
		}

		&.cell--gray {
			color: #7e8387;
		}
	}
}

.my-calendars {
	.my-calendars-header {
		cursor: pointer;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 10px;

		svg {
			transition: all .3s ease;
		}

		&.is-active {
			svg {
				transform: rotate(180deg);
			}
		}
	}

	.check-box {
		margin-bottom: 10px;
	}

	.check-box--blue{

		input:checked + label .check-icon {
			background-color: #039be5;
		}

		.check-icon {
			border-color: #039be5;
		}
	}

	.check-box--green{
		input:checked + label .check-icon {
			background-color: #18ac67;
		}

		.check-icon {
			border-color: #18ac67;
		}
	}

	.check-box--purple{
		input:checked + label .check-icon {
			background-color: #3f51b5;
		}

		.check-icon {
			border-color: #3f51b5;
		}
	}

	.check-box--blue-dark {
		input:checked + label .check-icon {
			background-color: #4285f4;
		}

		.check-icon {
			border-color: #4285f4;
		}
	}



	.check-icon {
		border-radius: 3px;
		border-width: 2px;

		&:before {
			color: #fff;
		}
	}
}