

/*------------------------------------*\
    #TRUMPS - HELPERS
\*------------------------------------*/

.is-hidden {
    display: none !important;
}

.is-invisible {
    visibility: hidden !important;
}

.is-scrollable-y {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.is-scrollable-x {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.u-no-transition {

    &,
    * {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -ms-transition: none !important;
        transition: none !important;
    }
}

.u-full-heght {
    height: 100% !important;
}

.u-text-center {
    text-align: center !important;
}

.u-text-left {
    text-align: left !important;
}

.u-text-right {
    text-align: right !important;
}

.u-align-middle {
    vertical-align: middle !important;
}

.u-align-top {
    vertical-align: top !important;
}

.u-align-bottom {
    vertical-align: bottom !important;
}

.u-float-left {
    float: left !important;
}

.u-float-right {
    float: right !important;
}

.u-inline-block {
    display: inline-block !important;
}

.u-display-block {
    display: block !important;
}

.italic {
    font-style: italic!important;
}

.w-auto {
    width: auto!important;
}

.w-100 {
    width: 100%!important;
}

.mw-100 {
    max-width: 100%!important;
}

.h-auto {
    height: auto!important;
}

.h-100 {
    height: 100%!important;
}

.relative {
    position: relative!important;
}

.clr-white {
	color: #fff!important;
}

.clr-dark {
	color: #070707!important;
}

.clr-green {
    color: #069c9e!important;
}

.font-300 {
	font-weight: 300!important;
}

.font-700 {
	font-weight: 700!important;
}

.text-decoration-none {
	text-decoration: none!important;
}

.border-right-radius-none {
    border-top-right-radius: 0!important;   
    border-bottom-right-radius: 0!important;   
}

.border-top-radius-none {
    border-top-right-radius: 0!important;   
    border-top-left-radius: 0!important;   
}

.border-left-radius-none {
    border-top-left-radius: 0!important;   
    border-bottom-left-radius: 0!important;   
}

.border-bottom-radius-none {
    border-bottom-right-radius: 0!important;   
    border-bottom-left-radius: 0!important;   
}

.font-16 {
    font-size: 16px!important;
}

.font-18 {
    font-size: 18px!important;
}

