/* ------------------------------------*\
    #HEADINGS
\*------------------------------------ */

h1,
h2,
h3,
h4,
h5,
h6 {
  >a {
    color: inherit;
  }
}

h1,
.h1 {}

h2,
.h2 {
  font-size: 24px;
}

h3,
.h3 {
  font-size: 20px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {}

h6,
.h6 {}
