.tree-box {
    padding-left: 20px;
    margin-left: 10px;
    position: relative;
    margin-top: 6px;
    margin-bottom: 0;

    &:before {
        position: absolute;
        left: 10px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #808080;
        content:"";
    }

    ul {
        position: relative;

        &:before {
            position: absolute;
            left: 10px;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: #808080;
            content:"";
        }
    }

    >li {

        >.list-heading {
            .tree-trigger {
                &:before {
                    display: none;
                }
            }
        }

        ul {
            display: none;
            padding-left: 20px;
            margin-left: 18px;
            margin-bottom: 0;
        }
    }

    .list-heading {
        display: flex;
        align-items: center;
        height: 40px;
        position: relative;
        padding: 0 20px;

        &:before {
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translate(16px, -50%);
            width: 25px;
            height: 1px;
            background-color: #808080;
            content:"";
        }

        a {
            text-transform: uppercase;
        }
    }

    .tree-trigger {
        display: flex;
        align-items: center;

        span {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #099395;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 5px;
                 
            &:before {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: "";
                transition: all .3s ease;
                visibility: visible;
                opacity: 1;
                line-height: 1;
                width: 0; 
                height: 0; 
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 7px solid #fff;
                margin-left: 1px;
            }

            &:after {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: "";
                width: 10px;
                height: 2px;
                background-color: #fff;
                transition: all .3s ease;
                visibility: hidden;
                opacity: 0;
                line-height: 1;
            }
        }


        &.is-active {

            span {

                &:before {
                    visibility: hidden;
                    opacity: 0;
                }

                &:after {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
