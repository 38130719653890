/* ------------------------------------*\
    #PAGE
\*------------------------------------ */


html {
  color: $color-text;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 400;
  background-color: #d1d8e7;
  overflow: hidden;
}

body {
  padding: 56px 10px 10px;
  display: flex;
  gap: 10px;
}

address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: $spacing-unit;
}

img {
  max-width: 100%;
}

main {
  display: flex;
  flex:1;
}

strong {
  font-weight: 700;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #e2e2e2;
  border-radius: 50px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #222;
  width: 4.5px;
}

::-webkit-scrollbar-thumb:horizontal{
  background: #222;
  border-radius: 50px;
}

* {
  scrollbar-color: #222 #e2e2e2;
  scrollbar-width: thin;
}