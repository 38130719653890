
.table-responsive {
	overflow: auto;
	// max-height: 670px;
}

.table {
	display: table;
	width: 100%;

	tr {
		&:first-child {
			th:first-child {
				background-color: #d8d8d9;
				border-bottom: 1px solid #a2a2a2;

			}
		}

		&:last-child {
			td:not(:first-child) {
				border-bottom: 1px solid #a2a2a2;
			}
		}

		&:nth-child(2n+2) {
			td {
				background-color: #f5f5f5;
			}
		}

		&:hover,
		&.active {
			td:first-child {
				background-color: #26b6b3;
			}

			td:not(:first-child) {
				background-color: #bfe8e7;
			}
		}

		&.red-row {
			td {
				background-color: #fb8faf;
				
				&.active-column {
					background-color: #bfe8e7;
				}
			}
		}

		&.green-row {
			td {
				background-color: #1ab9a5;
				
				&.active-column {
					background-color: #bfe8e7;
				}
			}
		}
	}

	th {
		border-top: 1px solid #a2a2a2;
		border-right: 1px solid #a2a2a2;
		padding: 10px;
		text-align: left;
		color: #000000;
		font-weight: 500;
		font-size: 12px;
		vertical-align: middle;

		&:not(:first-child) {
			border-bottom: 1px solid #a2a2a2;
		}
	}

	td {
		padding: 5px 10px;
		border-right: 1px solid #a2a2a2;
		color: #000000;
		font-size: 12px;
		vertical-align: middle;
		transition: all .3s ease;

		&:not(:first-child) {
			border-top: 1px solid #a2a2a2;
		}

		&.active {
			background-color: #26b6b3!important;
		}
	}

	&.table--no-row-numb {
		tr {
			&:last-child {
				td {
					border-bottom: 1px solid #a2a2a2!important;
				}
			}
		}
	}

	tfoot {
		td {
			border: none!important;
			border-top: 2px solid #069c9e!important;
		}
	}
}