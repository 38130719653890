.button {
  -webkit-font-smoothing: inherit;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  color: $color-base;
  transition: background-color .2s ease, color .2s ease;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: all .2s ease;
  background-color: transparent;
  cursor: pointer;
  font-family: $base-font-family;
  text-decoration: none;
  line-height: 1;

  &:not(:disabled) {

    &:hover {}

    &:focus {}

    &:active {
      outline: none;
      box-shadow: none !important;
      text-decoration: none;
    }
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed !important;
  }
}


.button--primary {
  display: inline-flex;
  align-items: center;
  justify-content: center; 
  height: 42px;
  width: 100%;
  max-width: 420px;
  padding:  $btn--primary-padding;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  border: 1px solid #4f8685;
  background-color: #2ab3b1;
  border-radius: 21px;

  &:not(:disabled) {

    &:hover,
    &:focus,
    &:active {
      background-color: #4f8685;
      color: #fff;
    }
  }
}

.button--secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center; 
  height: 42px;
  width: 100%;
  max-width: 420px;
  padding:  $btn--primary-padding;
  color: #fff;
  font-size: 22px;
  text-decoration: none;
  border: 1px solid #6b6b6b;
  background-color: #404040;
  border-radius: 21px;

  &:not(:disabled) {

    &:hover,
    &:focus,
    &:active {
      background-color: #6b6b6b;
    }
  }
}

.button--third {
  display: inline-flex;
  align-items: center;
  justify-content: center; 
  height: 42px;
  width: 100%;
  max-width: 420px;
  padding:  $btn--primary-padding;
  color: #444444;
  font-size: 22px;
  text-decoration: none;
  border: 1px solid #a5a5a5;
  background-color: #fff;
  border-radius: 21px;

  &:not(:disabled) {

    &:hover,
    &:focus,
    &:active {
      background-color: #6b6b6b;
      color: #fff;

    }
  }
}

.button--small {
  max-width: 270px;
}

.button--big {
  height: 78px;
  font-size: 33px;
  border-radius: 20px;
}

.button--green-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  width: 40px;
  text-decoration: none;
  background-color: #069c9e;
  border-radius: 50%;

 &:not(:disabled) {

    &:hover,
    &:focus,
    &:active {
      background-color: #4f8685;
    }
  }

  img {
    height: 60%;
    width: auto;
  }
}