.popup {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 9999; 
	transition: all 0.3s ease;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;

	&.is-active {
		visibility: visible;
		opacity: 1;
		pointer-events: all;


		.close-popup {
			visibility: visible;
			opacity: 1;
		}

		.popup__content {
			transform: translate(0,0);

		}
	}

	&.popup--big {
		.close-popup {
			left: 1825px;
		}

		.popup__content {
			max-width: 1825px;
		}
	}
}

.popup__overlay {
	background-color: rgba(#fff, .2);
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	position: relative;
	z-index: 1;
	
} 

.close-popup {
	position: absolute;
	left: 856px;
	top: 0;
	transform: translate(-60%, 50%);
	z-index: 10;
	transition: background-color 0.3s ease, visibility .3s ease .5s, opacity .3s ease .5s;
	visibility: hidden;
	opacity: 0;
}

.popup__content {
	position: absolute;
	left: 0;
	top: 10px;
	z-index: 2;
	height: calc(100% - 20px);
	width: 100%;
	max-width: 856px;
	background-color: #f1f1f1;
	border: 1px solid #3aadae;
	border-radius: 20px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	transition: all 0.3s ease .5s;
	transform: translate(-100%,0);

	.cart {
		margin-bottom: 10px;
	}

	.cart__body {
		border-top: 1px solid #069c9e;
		padding: 15px 15px 0;
		margin-top: 10px;
	}

	.cart__footer {
		border-top: 1px solid #069c9e;
		padding: 15px 15px 0;
		margin-top: 10px;
	}

	.cart--accordion {
		.cart__header {
			a {
				display: block;
				width: 100%;
				text-decoration: none;
				position: relative;

			}
			
			img {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(0,-50%);
				transition: all .3s ease;
			}

			&.is-active {
				img {
					transform: translate(0,-50%) rotate(180deg);
				}
			}
		}
	}

}

