/* ------------------------------------*\
    #link
\*------------------------------------ */

a {
  color: $color-link;
  transition: all .3s ease;
  text-decoration: none;

  &:hover {
    color: darken($color-link, 20%);
  }

  &:focus,
  &:active {
    outline: none;
    color: darken($color-link, 20%);
  }
}
