/**
* Reset input styles.
*/
.input {
  -webkit-appearance: none;
  /* removing inner shadow on iOS inputs */
  -webkit-font-smoothing: inherit;
  box-sizing: border-box;
  transition: all .5s ease;
  background: none;
  border: none;
  color: $color-base;
  width: 100%;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  padding: 0;
  /* remove browser defaults */
  vertical-align: middle;
  width: 100%;

  &:invalid {
    box-shadow: none;
    /* fixes mozilla red outline (shadow) */
  }

  &[readonly] {
    cursor: text;
  }

  &::-ms-clear {
    display: none;
    /* remove IE clear icon */
  }

  @mixin input-placeholder {
    color: $color-base;
  }

  &:not(:disabled) {

    &:hover {}

    &:focus {
      outline: none;
    }
  }
}

.input--primary {
  height: 39px;
  border-radius: 15px;
  background-color: #ffffff;
  border: 1px solid #444444;
  padding: 0 $input-padding;
  color: $color-input;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #060000;
  font-weight: 700;

  &:not(:disabled) {

    &:hover {}

    &:focus {}

    @mixin input-placeholder {
      color: $color-input-placeholder;
    }
  }

  /**
   * @disabled
   */
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.input--secondary {
  height: 44px;
  border-radius: 22px;
  background-color: #f5f5f5;
  border: 1px solid #76c6c7;
  padding: 0 $input-padding;
  color: $color-input;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #060000;


  &:not(:disabled) {

    &:hover {}

    &:focus {}

    @mixin input-placeholder {
      color: $color-input-placeholder;
    }
  }

  /**
   * @disabled
   */
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.input--third {
  height: 38px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #069c9e;
  border-bottom-width: 4px; 
  padding: 0 10px;
  color: $color-input;
  display: flex;
  align-items: center;
  font-size: 17px;
  color: #070707;
  font-weight: 700;
  width: 200px;


  &:not(:disabled) {

    &:hover {}

    &:focus {}

    @mixin input-placeholder {
      color: $color-input-placeholder;
    }
  }

  /**
   * @disabled
   */
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.input-box--search {

  svg {
    position: absolute;
    transform: translate(0, -50%);
    left: 20px;
    top: 50%;
  }

  input {
    padding-left: 50px;
    font-style: italic;
  }
}

.input-box--date {
  svg {
    position: absolute;
    transform: translate(0, -50%);
    right: 10px;
    top: 50%;
    background-color: #fff;
    pointer-events: none;
  }

  input {
    padding-right: 10px;
  }
}

.input-box {
  position: relative;
  margin-bottom: 10px;

  span {
    font-size: 15px;
    color: #070707;
    white-space: nowrap;
    display: inline-block;
  }

  p {
    margin-bottom: 0;
    display: inline-block;
    background-color: #fff;
    position: absolute;
    padding: 0 6px;
    left: 15px;
    bottom: 100%;
    transform: translate(0,50%);
  }

  select:not(.input--small).input {
    background-image:
    linear-gradient(45deg, transparent 50%, #000 50%),
    linear-gradient(135deg, #000 50%, transparent 50%),
    linear-gradient(to right, #fff, #fff);
    background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
    background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
    background-repeat: no-repeat;
    min-width: 80px;
    padding-right: 40px;
    font-size: 14px;
  }

  select.input--small {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
    background-size: 10px;
    background-position: calc(100% - 10px) 67%;
    background-repeat: no-repeat;
    font-weight: 400;
    font-size: 12px;
  }

  select.input--small.input--primary {
    height: 25px;
    padding-left: 15px;
    padding-right: 30px;
    font-size: 12px;
    color: #2ab3b1;
    min-width: 120px;
  }
}

.input--big {
  height: 78px;
  background-color: #f5f5f5;
  border-radius: 20px;
  font-size: 30px;
}

.input--small {
  height: 25px;
  border-radius: 10px;
  padding: 0 10px;
}




.check-box {
    vertical-align: middle;

    input {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
  
    label {
      display:flex;
      align-items:center;
      cursor: pointer;

    }

    .check-icon {
        width: 20px;
        height: 20px;
        border:1px solid #cccccc;
        background-color: #fff;
        position: relative;
        transition: all .3s ease;

        &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            content: "\2714";
            color: #20a0bc;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            font-size: 15px;
            transition: all .3s ease;
            visibility: hidden;
            opacity: 0;
        }

    }

    input:checked + label {
        .check-icon {
            &:before {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    input:disabled + label {
        pointer-events:none;
        opacity: .8;
        cursor: not-allowed;
    }
}