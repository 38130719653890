
.header-main {
    background-color: #444444;
	height: 46px;
	padding: 0 4px 0 20px;
	position:	absolute;
	left: 0;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;

	.refresh {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 38px;
		height: 38px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 3px solid #2ab3b1;
	}
}

.header-main__bars {
}

.nav__list {
	margin-bottom: 0;
	height: 100%;
}

.nav__list-item {
	height: 100%;
	display: flex;
	align-items: center;
	
	& + .nav__list-item {
		margin-left: 5px;
	}
}

.nav__link {
	width: 50px;
	height: 40px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #808080;
	border: 1px solid #070707;

	&.is-active,
	&:hover {
		background-color: #069c9e;
	}

	img {
		max-height: 80%;
	}
}
